<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('errauth[0]')"
            left-arrow
            @click-left="$router.go(-2)"
    />
    <div class="ScrollBox">
      <van-form class="mt15">
      <div class="panel">
        <div style="font-size: 18px;font-weight: bold">
          {{$t('common4[2]')}}:
        </div>
        <div style="margin-left: 20px">
          {{$t('errauth[1]')}}:
        </div>
        <div style="font-size: 16px;margin-bottom: 10px;margin-top: 10px">
          {{$t('common4[4]')}} {{parseFloat(UserInfo.err_usdt).toFixed(2)}} USDT
          ≈ {{Number(UserInfo.err_curr).toFixed(2) }} {{InitData.usdtinfo.sign}}
        </div>
      </div>

        <div style="text-align: center;margin-top: 10px">
          <van-button @click="$router.push('/user/recharge')">{{ $t("wallet.default[1]") }}</van-button>
        </div>
<!--        <div class="panel">-->
<!--          <div style="text-align: center;margin-top: 20px">-->
<!--            <van-uploader v-model="busauthInfo.screenshots1" max-count="1"-->
<!--                          :after-read="afterRead"/>-->
<!--            <div>{{$t('common4[5]')}}</div>-->
<!--          </div>-->

<!--          <div style="text-align: center;margin-top: 20px">-->
<!--            <van-uploader v-model="busauthInfo.screenshots2" max-count="1"-->
<!--                          :after-read="afterRead"/>-->
<!--            <div>{{$t('common4[6]')}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="text-align: center;margin-top: 20px">-->
<!--          <p class="btn" @click="submitAuth">{{$t('bindAccount.default[1]')}}</p>-->
<!--        </div>-->
      </van-form>
    </div>
    </div>
</template>

<script>
export default {
  name: 'Help',
  components: {
  },
  props: [],
  data() {
    return {
      showCon: false,
      showNotice: false,
      showRecharge:false,
      busauthInfo:{},
      infoData: '',
      busmoney:0,
    }
  },
  computed: {

  },
  watch: {
    "InitData.helpList"(data) {
      // if (!this.$parent.isNotice && data.length>1) this.showNotice = true;
    },
  },
  created() {
    this.$Model.GetUserInfo();
    let aa = this.UserInfo;
	  // let aa=JSON.parse(this.InitData.helpList[0].content)
	  // this.InitData.helpList.map(item=>JSON.parse(item.content))
	console.log("userinfo: "+this.UserInfo);
    // this.busmoney = this.InitData.setting.busmoney;
	// let balance = this.UserInfo.balance;
	// if (balance<this.busmoney){
	//   this.showRecharge = true;
    // }
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    openShow(data) {
      this.showCon = true
      this.infoData = data
    },
    submitAuth(){
      this.$Model.GoShimingAuth(this.busauthInfo,data=>{
        var msg = '';
        if (data.code==1){
          msg = this.$t('common4[1]');
        }else if (data.code==0){
          msg = data.code_dec
        }
        this.$Dialog.Toast(msg);
        if (data.code==1){
          this.$router.replace("/");
        }

      });
    },
    afterRead(file) {
      debugger
      file.status = 'uploading'
      file.message = this.$t('upload[0]')
      this.uploadImgs(file)
    },
    uploadImgs(file) {
      if (!file.file.type.match(/image/)) {
        file.status = "failed";
        file.message = this.$t("upload[1]");
        return;
      }
      console.log(2);
      this.compressImg(file);
    },
    compressImg(file) {
      this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
        let param = new FormData();
        param.append("token", localStorage["Token"]);
        param.append("type", 3);
        param.append("image", image, file.file.name);
        this.$Model.UploadImg(param, (data) => {
          if (data.code == 1) {
            file.message = this.$t("upload[2]");
            file.status = "success";
            file.url = data.url;
          } else {
            file.status = "failed";
            file.message = this.$t("upload[3]");
          }
        });
      });
    }
  }
}
</script>
<style scoped>
  .PageBox {

  }


  .PageBox>>>.tool::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: -80%;
  }
  .tool {
    border-radius: 10px;
    background-color: #F46926;
    color: #ffffff;
    height: 120px;
    box-shadow: 0 1px 3px #dbdbdbdb;
    overflow: hidden;
    z-index: 99;
    padding: 0 5px;
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .panel {
    padding: 10px;
    background-color: var(--panel_color);
    border-radius: 10px;
  }

.Content>>>img{
  max-width: 100%;
}
.van-cell__title{
  font-size: 14px;
  color: #000;
}

/* .van-nav-bar>>>.van-icon{
	color:#fff;
} */

.ScrollBox{
	padding:0 15px;
}
.ScrollBox>>>.van-cell{
	padding:20px 5px;
	border-bottom: 1px solid #D9D9D9;
}
.ScrollBox>>>.van-cell:last-child{
	border-bottom: 0px solid #D9D9D9;
}
.ScrollBox>>>.van-cell::after{
	display: none;
}
.ScrollBox>>>.van-icon-chat-o::before{
	content: '';
	border-radius: 50%;
	background: #B3B5FC;
	width:8px;
	height: 8px;
}
.ScrollBox>>>.van-icon-chat-o{
	display: flex;
	align-items: center;
	justify-content: center;
}
.ScrollBox>>>.van-cell__title{
	font-size: 16px;
	font-family: ui-serif;
}
.ScrollBox>>>h3{
	font-size: 1.05rem;
	font-family: emoji;
}
.van-cell>>>.van-icon{
	font-size: 18px;
	color:#333;
}
.PageBox>>>.van-popup__close-icon--top-right{
	color:#333;
	top:20px;
	right: 20px;
}
.PageBox>>>.Content{
	margin-top:40px;
	padding:0 20px;
}
.PageBox>>>.Content p{
	font-size: 13px;
	line-height: 1.7;
	font-family: 'vant-icon';
	font-weight: 400;
	color: #666666;
}
  .btn {
    padding: 10px 50px;
    border-radius: 5px;
    background-color: #F4D34F;
    color: #000;
    font-size: 20px;
    text-align: center;
    margin: 15px 10px 30px;
  }
</style>
